.not-found {
  text-align: center;
  padding: 50px 20px;
  max-width: 600px;
  margin: 0 auto;
}

.not-found h1 {
  font-size: 120px;
  margin: 0;
  color: #e74c3c;
}

.not-found h2 {
  font-size: 24px;
  margin: 20px 0;
  color: #2c3e50;
}

.not-found p {
  color: #7f8c8d;
  margin-bottom: 30px;
}
w
.home-button {
  display: inline-block;
  padding: 10px 20px;
  background-color: #3498db;
  color: white;
  text-decoration: none;
  border-radius: 4px;
  transition: background-color 0.3s;
}

.home-button:hover {
  background-color: #2980b9;
} 