.video-container {
    width: 100%;
    height: 100%;
    position: relative;
    overflow: hidden;
}

video {
    width: 100%;
    height: 100%;
    object-fit: cover;
    display: block;
}