.home {
  max-width: 800px;
  margin: 0 auto;
  padding: 40px 20px;
  text-align: center;
}

.home h1 {
  font-size: 2.5em;
  color: #2c3e50;
  margin-bottom: 20px;
}

.home p {
  font-size: 1.2em;
  color: #7f8c8d;
  line-height: 1.6;
} 