.post-list {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
}

.post-list h2 {
  margin-bottom: 20px;
}

.new-post-button {
  display: inline-block;
  padding: 8px 16px;
  background-color: #007bff;
  color: white;
  text-decoration: none;
  border-radius: 4px;
  margin-bottom: 20px;
}

.post-item {
  border: 1px solid #ddd;
  margin-bottom: 10px;
  padding: 15px;
  border-radius: 4px;
}

.post-item a {
  text-decoration: none;
  color: inherit;
}

.post-item h3 {
  margin: 0 0 10px 0;
}

.post-meta {
  display: flex;
  gap: 15px;
  color: #666;
  font-size: 0.9em;
  margin: 10px 0;
  justify-content: center;
}

.post-author {
  color: #666;
}

.post-date {
  color: #666;
}

.post-detail {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
}

.post-content {
  margin: 20px 0;
  line-height: 1.6;
  white-space: pre-wrap;
}

.post-actions {
  margin-top: 20px;
}

.post-actions button {
  margin-right: 10px;
  padding: 8px 16px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.post-actions button:hover {
  background-color: #0056b3;
}

.post-form {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
}

.form-group {
  margin-bottom: 20px;
}

.form-group label {
  display: block;
  margin-bottom: 5px;
}

.form-group input,
.form-group textarea {
  width: 100%;
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
}

.form-group textarea {
  height: 200px;
  resize: vertical;
}

.form-actions {
  margin-top: 20px;
}

.form-actions button {
  margin-right: 10px;
  padding: 8px 16px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.form-actions button:hover {
  background-color: #0056b3;
}

.form-actions button[type="button"] {
  background-color: #6c757d;
}

.error-message {
  color: #e74c3c;
  text-align: center;
  padding: 20px;
  margin: 20px;
  border: 1px solid #e74c3c;
  border-radius: 4px;
  background-color: #fdf3f2;
} 