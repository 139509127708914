.nav-menu {
  background-color: #333;
  padding: 1rem;
  margin-bottom: 2rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 1rem;
}

.nav-left {
  display: flex;
  gap: 0.2rem;
}

.nav-right {
  margin-left: auto;
  display: flex;
  align-items: center;
  gap: 0.2rem;
  
}

.nav-menu a, .nav-menu button, .nav-menu span {
  color: rgb(255, 255, 255) !important;
  text-decoration: none;
  border-radius: 4px;
  transition: background-color 0.3s;
  padding: 0.4rem 0.5rem !important;
}

.nav-menu a:hover {
  background-color: #555;
}

.login-button, .signup-button, .logout-button {
  background-color: #007bff;
  border: none;
  color: red !important;
  cursor: pointer;
  font-size: 1rem;
  padding: 0.4rem 0.5rem !important;
}

.signup-button {
  background-color: #28a745 !important;
}

.login-button {
  background-color: #007bff !important;
}

.logout-button {
  background-color: #dc3545 !important;
}

.login-button:hover {
  background-color: #0056b3 !important;
}

.signup-button:hover {
  background-color: #218838 !important;
}

.logout-button:hover {
  background-color: #c82333 !important;
}

.user-name {
  color: rgb(255, 255, 255) !important;
  margin-right: 0.5rem;
} 